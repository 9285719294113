<template>
  <div>
    <ui-modal ref="Modal"   v-bind="modalProps" v-on="$listeners">
      <el-form  ref="form"   :rules="rules"  inline :model="CreateTask" @input="change($event)" label-width="100px" style="algin:center"  hide-required-asterisk>
        <el-form-item label="标题" prop="task_theme">
          <el-input  size="medium"  v-model="CreateTask.task_theme" clearable placeholder="请输入任务标题" /></el-form-item>
        <el-form-item label="间隔" prop="scheduler_params">
          <el-input  size="medium"  v-model="scheduler_params.scheduler_second"  @change="intervalInput"  style="width:200px" >
            <el-select  v-model="name"  slot="append"  class="topselec" placeholder="选择" style="width: 80px;">
              <el-option value="second" label="秒"/>
              <el-option value="minute" label="分"/>
              <el-option value="hour" label="时"/>
              <el-option value="day" label="日"/>
              <el-option value="month" label="月"/>
              <el-option value="year" label="年"/>
            </el-select>
            </el-input>
        </el-form-item>
        <el-form-item label="过滤近期发送"  prop="filter_condition">
          <el-radio v-model="CreateTask.filter_condition" :label="1" :disabled="CreateTask.value1 ?true : false">近1天</el-radio>
          <el-radio v-model="CreateTask.filter_condition" :label="3" :disabled="CreateTask.value1 ?true : false">近3天</el-radio>
          <el-radio v-model="CreateTask.filter_condition" :label="7" :disabled="CreateTask.value1 ?true : false">近7天</el-radio>
          <el-input  v-model="CreateTask.value1" style="width:60px"  @input="filterDay"  onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8" > </el-input>天
        </el-form-item>
        <el-form-item label="过滤已购天数" prop="purchase_time_filter">
          <el-radio v-model="CreateTask.purchase_time_filter" :label="0" :disabled="CreateTask.value2 ?true : false">不过滤</el-radio>
          <el-radio v-model="CreateTask.purchase_time_filter" :label="1" :disabled="CreateTask.value2 ?true : false">近1天</el-radio>
          <el-radio v-model="CreateTask.purchase_time_filter" :label="3" :disabled="CreateTask.value2 ?true : false">近3天</el-radio>
          <el-radio v-model="CreateTask.purchase_time_filter" :label="7" :disabled="CreateTask.value2 ?true : false">近7天</el-radio>
          <el-input  v-model="CreateTask.value2" style="width:60px"  @input="filterBuy" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"> </el-input>天
        </el-form-item>
      </el-form>
    </ui-modal>
  </div>
</template>
<script>
import {errorAlert,successAlert}from "../../../utils/alert"
export default {
  props: ["row"],
  data() {
    const { row } = this
    return {
        fromkey:[],
        entity:{},
        time:null,
        name:'',
        ranParam:{...row},
        value:'',
        select:'',
        smsTemplates:'',
        modalProps: {
        title: "创建周期任务",
        okText: "确认创建",
        width: "50%",
        onSubmit: async () => {
            await this.$refs.form.validate();
          if( this.ranParam.sql_text == undefined){
              return  this.$message({
                message: '警告,您还没有查询出结果，请返回查询',
                type: 'warning'
              });
            }
          if(!this.name){
                return   this.$message({
                message: '错误,您没有选择时间单位，请重新操作',
                type: 'error'
              });
          }
          if(!this.scheduler_params.scheduler_second){
                return  this.$message({
                message: '错误,您没有填写时间，请重新操作',
                type: 'error'
              });
          }
          this.fromkey[this.name] = this.scheduler_params.scheduler_second
          if(this.fromkey[this.name] == ''||this.fromkey[this.name] == null||!this.fromkey){
            return this.$message({
                message: '错误,您没有正确创建任务，请重新操作',
                type: 'error'
              });
          }
            let keys = Object.keys(this.fromkey)[0];
            if(keys.includes("year")){
                this.scheduler_params.scheduler_second = this.fromkey[keys]*365*24*60*60;
            }else if(keys.includes("month")){
                this.scheduler_params.scheduler_second = this.fromkey[keys]*30*24*60*60;
            }else if(keys.includes("day")){
                this.scheduler_params.scheduler_second = this.fromkey[keys]*24*60*60;
            }else if(keys.includes("hour")){
                 this.scheduler_params.scheduler_second = this.fromkey[keys]*60*60;
            }else if(keys.includes("minute")){
                 this.scheduler_params.scheduler_second = this.fromkey[keys]*60;
            }
          this.interval_params.scheduler_second=Number(this.scheduler_params.scheduler_second);
          if(!this.ranParam.smsTemplatContent.includes("@var(short_url)")){
              let params={
              task_theme:this.CreateTask.task_theme,
              template_id:this.ranParam.template_id,
              filter_condition:this.CreateTask.filter_condition,
              purchase_time_filter:this.CreateTask.purchase_time_filter,
              sql_text:this.ranParam.sql_text,
              scheduler_params: this.interval_params,
            }
              const res = await this.$http.post("/periodic_task/scheduler_job/create",params);
                if(res.data.code ==0){
                successAlert(res.data.msg)
                  }else{
                errorAlert(res.data.msg)
                }
            }else{
              let params={
                long_url:this.CreateTask.long_url,
                task_theme:this.CreateTask.task_theme,
                template_id:this.ranParam.template_id,
                filter_condition:this.CreateTask.filter_condition,
                purchase_time_filter:this.CreateTask.purchase_time_filter,
                sql_text:this.ranParam.sql_text,
                scheduler_params: this.interval_params,
            }
            this.$prompt('由于您选中的短信模版中含短网址参数,请输入长链接URL:', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                model:"CreateTask.long_url",
                inputPattern:  /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i,
                inputErrorMessage: 'URL格式不正确'
            }).then(({ value }) => {
                params.long_url = value 
                this.CycleTask(params) 
                }).catch(()=>{
                    this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
                });
             }
        },
      },
      
        CreateTask:{
          task_theme:"",
          template_id:"",
          long_url:"",
          filter_condition:null,
          purchase_time_filter:null,
          sql_text:"",
          scheduler_params:{},
        },

         scheduler_params:{
          scheduler_type:"",
          scheduler_year: null,
          scheduler_month:null,
          scheduler_day:null,
          scheduler_hour:null,
          scheduler_minute:null,
          scheduler_second:null
        },

         interval_params: {
        scheduler_type: "interval",
        scheduler_second: ''
    },
      
      rules: {
        task_theme: [{ required: true, message: "请输入任务标题", trigger: "blur" }],
        filter_condition: [{ required: true, message: '请选择过滤近期发送'}],
        purchase_time_filter: [{ required: true, message: '请选择过滤已购天数'}],
      },
    };
  },

  created(){
  },

  watch:{
    name(newV,oldV){
    },
  },

  methods: {
    
     async CycleTask(params){
      const res =  await this.$http.post("/periodic_task/scheduler_job/create",params)
      if(res.data.code ==0){
        successAlert(res.data.msg)
      }else{
        errorAlert(res.data.msg)
      }
    },

    filterDay(val){
    this.CreateTask.filter_condition=val
    },

    filterBuy(val){
      this.CreateTask.purchase_time_filter=val
    },

    intervalInput(val){},

  },
};
</script>
<style lang="scss" scoped>
.changeInput{
  height: 50px;
}
.el-divider{
  cursor: pointer;
}
/deep/.el-upload__tip {
  color: #999;
}
/deep/.el-upload-dragger {
  
  .el-upload__text {
    font-size: 12px;
  }
}
/deep/.el-form-item__label {
  margin-top: 10px;
  font-size: 14px;
}
/deep/.el-input--small .el-input__inner {
  margin-top: 10px;
}
/deep/.el-radio {
  margin-top: 20px;
}
/deep/ .el-form-item.is-error  .el-input__inner{
        border-color: #EBEEF5;
      } 
</style>
